<template>
  <!-- 服务记录详情 -->
  <div>
    <el-descriptions class="margin-top" title="服务记录详情" :column="2" :size="size" border>
        <el-descriptions-item label="服务对象"><p  @click="goPage()" style="cursor:pointer; color:#2d8cf0;">{{form.oldPersonName}}</p></el-descriptions-item>
        <el-descriptions-item label="服务公司"><span style="color:#2d8cf0;">{{form.workPersonOrgName}}</span></el-descriptions-item>
        <el-descriptions-item label="联系方式">{{form.tel}}</el-descriptions-item>
        <el-descriptions-item label="服务评分">{{form.serviceScore}}</el-descriptions-item>
        <el-descriptions-item label="服务员工">{{form.workPersonName}}</el-descriptions-item>
        <el-descriptions-item label="服务来源">{{form.source}}</el-descriptions-item>
        <el-descriptions-item label="服务项目">{{form.serviceItem}}</el-descriptions-item>
        <el-descriptions-item label="服务分值">{{form.satisfaction}}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{form.statusName}}</el-descriptions-item>
        <el-descriptions-item label="服务区域">{{form.province}}{{form.city}}{{form.area}}{{form.street}}{{form.address}}</el-descriptions-item>
        <el-descriptions-item label="服务起止时间">{{form.serviceBeginTime}} ~ {{form.serviceEndTime}}</el-descriptions-item>
        <el-descriptions-item label="服务时长">{{form.serviceTime}}</el-descriptions-item>
        <el-descriptions-item label="服务内容">{{form.serviceContent}}</el-descriptions-item>
        <el-descriptions-item label="服务说明">{{form.serviceContentDesc}}</el-descriptions-item>
        <el-descriptions-item label="服务前照片" >
        <div class="block" v-for="(fit,index) in imgsListBefore" :key="index">
          <el-image
            style="width: 100px; height: 100px"
            :src="fit.url"
 
            :preview-src-list="[fit.url]"
          ></el-image>
        </div>
          <!-- <upload-img :limit="6" :uploadLists="imgsListBefore" :class="{ disabled: true}"></upload-img> -->
        </el-descriptions-item>
        <el-descriptions-item label="服务后照片" >
          <div class="block" v-for="(fit,index) in imgsListAfter" :key="index"  >
          <el-image
            style="width: 100px; height: 100px"
            :src="fit.url"

            :preview-src-list="[fit.url]"
          ></el-image>
        </div>
        </el-descriptions-item>
        <el-descriptions-item label="开始服务">
          <div id="container" style="height: 240px;width:240px"></div>
        </el-descriptions-item>
        <el-descriptions-item label="结束服务">
          <div id="container2" style="height: 240px;width:240px"></div>
        </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/store/API/api'
import UploadImg from '@/components/UploadImg.vue'
import axios from 'axios'
export default {
    name:"serRecordDetail",
      components: {
      UploadImg,
    },
    data () {
      return {
        size: '',
        imgsListBefore:[],
        imgsListAfter:[],
        srcListBefore:[],
        srcListAfter:[],
        headers: { Authorization: sessionStorage.token },
        uploadApi: process.env.NODE_ENV === 'development' ? '/api' + api.fileUpload : process.env.VUE_APP_BASEURL + api.fileUpload,   
        form:{}
      }
    },
    created() {
      this.getServeRecordsDetail()
    },
      methods: {
        ...mapActions([
          "detailServeRecordsServe"
        ]),
        getServeRecordsDetail() {
          let params = {
            id: this.$route.params.id,
          }
          this.detailServeRecordsServe(params).then((res) => {
            if (res.code == 200) {
              var text = ''
            switch (res.data.source) {  // 进入判断逻辑
              case 1:
                text = '服务企业(居家养老)';
                break;
              case 4:
                text = ' 医院';
                break;
              case 5:
                text = ' 商家';
                break;
                case 6:
                text = ' 机构养老';
                break;
                case 7:
                text = ' 志愿者服务';
                break;
                case 11:
                text = '服务站(社区养老)';
                break;
                case 12:
                text = '  日照中心(社区养老)';
                break;
                case 13:
                text = ' 综合养老服务中心(社区养老)';
                break;
                case 14:
                text = ' 社区医疗机构(社区养老)';
                break;
                case 21:
                text = ' 养老早市';
                break;
                case 22:
                text = ' 老年餐';
                break;
                case 23:
                text = ' 移动餐车';
                break;
                case 31:
                text = '  农村幸院';
                break;
                case 51:
                text = ' 适老化改造施工单位';
                break;
               
              default:
                text = ' 其他';
            }
            res.data.source = text
              res.data.serviceTime =Math.floor(res.data.serviceTime/60)+ "小时" + (res.data.serviceTime%60) + "分" ;
              this.form = {
                id: this.$route.params.id,
                ...res.data,
              }
              // 如果图片存在，查图片详情
              if(this.form.beforeImgs) {
                this.getFileDetail(this.form.beforeImgs, 1)
              } 
              if(this.form.afterImgs) {
                this.getFileDetail(this.form.afterImgs, 2)
              }
              this.initMap(res.data. beforeLongitude,res.data.beforeLatitude,res.data.afterLongitude,res.data.afterLatitude)
              // setTimeout(this.initMap(),10000);
            }
          })
    },
    getFileDetail(ids, type) {
      ids = ids.split(',')
      ids.forEach(item => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            'Authorization': sessionStorage.token,
          },
        }).then((res) => {
          if(type == 1) {
            this.imgsListBefore.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
            
            this.srcListBefore.push(window.URL.createObjectURL(res.data))
          }else{
            this.imgsListAfter.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
            this.srcListAfter.push(window.URL.createObjectURL(res.data))
          }
        })
      })
    },
    /**
     * 点击老人姓名跳转基本信息
     */

    goPage(){
      		this.$router.push({
						name: 'AddPerson',
						params: {
							type: 'detail',
							id: JSON.parse(this.$route.query.item).serviceOldPersonId
						}
					})
    },

    /**
     * longitude1 ，latitude1  服务前经度，纬度；
     * longitude2 ，latitude2  服务后经度，纬度；
     */
    initMap(longitude1,latitude1,longitude2,latitude2) {
      this.map = new BMapGL.Map("container"); //创建一个地图实例
      this.map2 = new BMapGL.Map("container2"); //创建一个地图实例
      let myIcon = new BMapGL.Icon(
        require("@/assets/images/dian.png"),
        new BMapGL.Size(12, 26)
      );
      // var point = new BMapGL.Point(116.404, 39.915);  // 创建点坐标 
      // if(longitude2==null||longitude2==undefined){
      //   longitude2 = longitude1
      // }else if(latitude2==null||latitude2==undefined){
      //   latitude2 = latitude1
      // }
      var point = new BMapGL.Point(longitude1, latitude1);  // 创建点坐标  
      // var point2 = new BMapGL.Point(longitude2, latitude2);  // 创建点坐标 


      let marker = new BMapGL.Marker(point, {
        icon: myIcon,
      });
      let marker2 = new BMapGL.Marker(point, {
        icon: myIcon,
      });
      this.map.addOverlay(marker);
      this.map2.addOverlay(marker2);
      this.map.centerAndZoom(point, 15);  
      this.map2.centerAndZoom(point, 15);  

      // this.map.centerAndZoom(new BMapGL.Point(116.404, 39.915), 13); //设置坐标点和展示级别
      // this.map.setMapStyleV2({
      //   styleId: "9e19b3c9f41d83f25b8c14757ec8dfac", //样式ID
      // });
      this.map.enableScrollWheelZoom(true); // 允许地图可被鼠标滚轮缩放
      this.map2.enableScrollWheelZoom(true); // 允许地图可被鼠标滚轮缩放

      var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
      this.map.addControl(scaleCtrl);
      this.map2.addControl(scaleCtrl);


      //创建图标
      // let myIcon = new BMapGL.Icon(
      //   require("@/assets/images/dian.png"),
      //   new BMapGL.Size(12, 26)
      // );
      // // 创建Marker标注，使用图标
      // let point = new BMapGL.Point("116.404", "39.915");
      // let marker = new BMapGL.Marker(point, {
      //   icon: myIcon,
      // });
      // 将标注添加到地图
      // this.map.addOverlay(marker);

      // 信息窗口内容----点击图标打开信息窗口
      // let opts = {
      //   width: 60, // 信息窗口宽度
      //   height: 50, // 信息窗口高度
      // };
      // let content = ` <div class="alarmDiv">
      //                   <div class="imgbj">
      //                     <div class="imgtitle">内容</div>
      //                   </div>
      //                   <p>标题</p>
      //                 </div>`;
      // let infoWindow = new BMapGL.InfoWindow(content, opts); // 创建信息窗口对象
      // marker.addEventListener("click", () => {
      //   this.map.openInfoWindow(infoWindow, point); //开启信息窗口
      // });
    },
      }
    
}
</script>

<style lang="scss" scoped>
  .block{
    float: left;
    margin-right: 10px;
  }
</style>>
